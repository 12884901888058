@import "media";
@import "mixins";
@import "../settings";

sub {
  vertical-align: sub;
  font-size: small;
}

sup {
  vertical-align: super;
  font-size: small;
}

p {
  margin: 1em 0;
  line-height: 1.3;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

// hide placeholders on focus
input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

.field-bc {
  display: none;
}

.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

body {
  font-family: $font-family;
  font-size: $font-size;
  color: $textColor;
  line-height: 1;
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
}

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.fade {
  display: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.smaller {
  font-size: 90%;
}

:focus {
  outline: none;
}

:focus::-webkit-input-placeholder {
  color: transparent
}

:focus::-moz-placeholder {
  color: transparent
}

:focus:-moz-placeholder {
  color: transparent
}

:focus:-ms-input-placeholder {
  color: transparent
}

/* Micro ClearFix Mixin */
.clearfix {
  zoom: 1;
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently
                         not supported by any browser */
}

.grid {
  margin: 0 -$grid-gap/2;

  > .item {
    display: inline-block;
    vertical-align: top;
    margin: 0 $grid-gap/2 $grid-gap;
    box-sizing: border-box;
  }
}

.flex-grid {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -$grid-gap/2;

  &__item {
    margin: 0 $grid-gap/2 $grid-gap;
    box-sizing: border-box;

    @include respond-to(mobile) {
      width: 100%;
    }
  }
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;

  img {
    align-self: flex-start;
  }
}

html {
  min-width: 1300px;
  width: 100%;
  @include respond-to(550) {
    min-width: 0;
  }
}

.container {
  @extend .block-center;
  position: relative;
  max-width: $site-width;
  min-width: 1300px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  @include respond-to(550) {
    min-width: 0;
  }
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mrg {
  @extend .mrg-top;
  @extend .mrg-bottom;
}

.mrg-top {
  margin-top: $margin;
}

.mrg-bottom {
  margin-bottom: $margin;
}

input[name='BC'] {
  display: none;
}

form .form-group {
  display: inline-block;
  vertical-align: top;
}

input, textarea {
  font-family: $font-family;
}


.link {
  display: inline-block;
  border-bottom: 1px solid;
  color: inherit;
  text-decoration: none;
  @extend .transition;

  &:hover {
    border-color: transparent;
  }
}

.linkReverse {
  display: inline-block;
  border-bottom: 1px solid transparent;
  color: inherit;
  text-decoration: none;
  @extend .transition;

  &:hover {
    border-color: inherit;
  }
}

.linkSpan {
  display: inline-block;
  color: inherit;
  text-decoration: none;

  span {
    border-bottom: 1px solid;
    @extend .transition;
  }

  &:hover {
    span {
      border-color: transparent;
    }
  }
}

.linkSpanReverse {
  display: inline-block;
  color: inherit;
  text-decoration: none;

  span {
    border-bottom: 1px solid transparent;
    @extend .transition;
  }

  &:hover {
    span {
      border-color: inherit;
    }
  }
}
