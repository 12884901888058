$site-width: 1280px;
$big-site-width: 1680px;

$font-family: MuseoSans, Arial, sans-serif;
$font-size: 16px;

$grid-gap: 10px;

$margin: 30px;

$textColor: #000;
$headerColor: #ffeed9;
$footerLineColor: #91908e;
$subscribeFormColor: #585858;