@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-100.eot');
  src: url('fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-100.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-100.woff') format('woff'),
  url('fonts/MuseoSansCyrl-100.svg#MuseoSansCyrl-100') format('svg');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.thin {
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-100Italic.eot');
  src: url('fonts/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-100Italic.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-100Italic.woff') format('woff'),
  url('fonts/MuseoSansCyrl-100Italic.svg#MuseoSansCyrl-100Italic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.thin-italic {
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-300.eot');
  src: url('fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-300.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-300.woff') format('woff'),
  url('fonts/MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.light {
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-500.eot');
  src: url('fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-500.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-500.woff') format('woff'),
  url('fonts/MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.regular {
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-700.eot');
  src: url('fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-700.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-700.woff') format('woff'),
  url('fonts/MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.bold {
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-700Italic.eot');
  src: url('fonts/MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-700Italic.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-700Italic.woff') format('woff'),
  url('fonts/MuseoSansCyrl-700Italic.svg#MuseoSansCyrl-700Italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.bold-italic {
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-900.eot');
  src: url('fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-900.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-900.woff') format('woff'),
  url('fonts/MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.black {
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family:'MuseoSans';
  src: url('fonts/MuseoSansCyrl-900Italic.eot');
  src: url('fonts/MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-900Italic.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-900Italic.woff') format('woff'),
  url('fonts/MuseoSansCyrl-900Italic.svg#MuseoSansCyrl-900Italic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.black-italic {
  font-weight: 900;
  font-style: italic;
}